import Vue from 'vue';
import gql from 'graphql-tag';
import { DateTime as LuxonDateTime } from 'luxon';
import groupBy from 'lodash.groupby';

import apollo from '@/services/apollo';
import DoublePaneLayout from '@/components/Admin/DoublePaneLayout/DoublePaneLayout';

import './Bookings.css';

export interface BookingModel {
  confirmed: boolean;
  created: string;
  id?: string;
  firstName: string;
  lastName: string;
  message?: string;
  phone: string;
  selectedDate: string;
  selectedDay: string;
  selectedOffering: string;
  selectedTime: string; // Time slot chose by client
}

export interface BookingsProps {
  allBookings: BookingModel[];
  bookings: BookingModel[] | null;
  loading: boolean;
  selectedDate: string;
}

const BookingSidebarItem = (context: any) => {
  const booking = context.props.item as BookingModel;

  return (
    <li class="booking-sidebar-item">
      <router-link to={`/admin/bookings/${booking.id}?date=${booking.selectedDay}`}>
        <div>
          <span class="bookings-sidebar-item__time">{booking.selectedTime}</span>
          <span class="bookings-sidebar-item__name">
            {booking.firstName} {booking.lastName}
          </span>
          <span class="bookings-sidebar-item__plan">
            <em>Package: </em>
            {
              booking.selectedOffering
                ? booking.selectedOffering.split('|')[1].trim()
                : 'No plan selected'
            }
          </span>
          <span class={[
            'bookings-sidebar-item__confirmed',
            booking.confirmed ? 'success' : 'pending',
          ].join(' ')}>
            {
              booking.confirmed
                ? 'Confirmed'
                : 'Pending'
            }
          </span>

        </div>
      </router-link>
    </li>
  );
};

const Bookings = Vue.extend({
  data(): BookingsProps {
    return {
      allBookings: [],
      bookings: null,
      loading: true,
      selectedDate: '',
    };
  },

  methods: {
    changeSelectedDay(args: { dateStr: string }) {
      const { dateStr } = args;

      this.selectedDate = dateStr;
    },

    loadBookingsList() {
      const query = gql`
        {
          getBookings{
            confirmed
            created
            email
            firstName
            id
            lastName
            phone
            message
            selectedDate
            selectedDay
            selectedOffering
            selectedTime
          }
        }
      `;

      apollo.query({
        fetchPolicy: 'network-only',
        query,
      })
        .then(({ data }) => {
          this.allBookings = (data?.getBookings || [])
            .map((booking: BookingModel) => ({
              ...booking,
              // selectedDay: LuxonDateTime.fromISO(booking.selectedDate).toISODate(),
            }));
        })
        .catch((err) => {
          console.log('Error loading bookings => ', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    loadGroupedBooking() {
      this.$store.commit('updateAllBookings', this.allBookings);

      // TODO: Show this grouped bookings in the UI
      const groupedBookings = groupBy(this.allBookings, 'selectedDay');

      this.$store.commit('updateGroupedBookings', groupedBookings);

      this.bookings = this.allBookings;

      this.loadSelectedDate();
    },

    loadSelectedDate() {
      const visibleBookings = this.allBookings?.filter(
        booking => booking.selectedDay === this.selectedDate,
      );

      this.bookings = visibleBookings;
    },

    // Update a booking item in the sidebar when it is edited
    updateBookingItem(booking: BookingModel) {
      const index = this.allBookings.findIndex(item => item.id === booking.id);
      if (index === -1) return;

      const allBookings = [...this.allBookings];

      allBookings[index] = {
        ...allBookings[index],
        ...booking,
      };

      this.allBookings = allBookings;
    },

    updateCurrentDate() {
      const preselectedDay = (this.$route.query.date as string);

      this.selectedDate = preselectedDay
        ? LuxonDateTime.fromISO(preselectedDay).toISODate()
        : LuxonDateTime.fromJSDate(new Date()).toISODate();

      if (this.$route.query.refresh) {
        this.loadBookingsList();
      }
    },
  },

  mounted() {
    this.loadBookingsList();
    this.updateCurrentDate();
  },

  name: 'bookings',

  render() {
    const selectedDate = this.selectedDate
      ? LuxonDateTime.fromISO(this.selectedDate)
      : LuxonDateTime.fromJSDate(new Date());

    const BookingSidebarHeader = () => (
      <div class="bookings-sidebar-header">
        <span class="bookings-sidebar-header__day">{selectedDate.day}</span>
        <span class="generic-label">{selectedDate.weekdayLong}</span>
        <span class="bookings-sidebar-header__month">{selectedDate.monthLong}</span>
      </div>
    );

    return (
      <DoublePaneLayout
        filterHandler={null}
        renderSidebarHeader={BookingSidebarHeader}
        renderSidebarItem={BookingSidebarItem}
        search={null}
        sidebarLoading={this.loading}
        sidebarItems={this.bookings}
        sidebarLink={`/admin/bookings/new?${this.selectedDate ? `date=${this.selectedDate}` : ''}`}
        sortHandler={null}
      >
        <router-view {...{
          allBookings: this.allBookings,
          on: {
            changeSelectedDay: this.changeSelectedDay,
            reloadBookingsList: this.loadBookingsList,
            updateBookingItem: this.updateBookingItem,
          },
        }}></router-view>
      </DoublePaneLayout>
    );
  },

  watch: {
    $route: 'updateCurrentDate',
    allBookings: 'loadGroupedBooking',
    selectedDate: 'loadSelectedDate',
  },
});

export default Bookings;
